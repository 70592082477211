/* Constants */
import { AD_GROUP, INTERNAL_AD_GROUP, EXTERNAL_AD_GROUP } from './adGroups'

export const TARGET_SETUP_PAGE = {
  TARGET_ACCESS_SECTION_INTERNAL: INTERNAL_AD_GROUP,
  SUPPLIER_TYPE_EDIT_INTERNAL: [
    AD_GROUP.UPDATE,
    AD_GROUP.APPROVE,
    AD_GROUP.SECURITY,
    AD_GROUP.SYSADMIN,
  ],
  TARGET_CONTACT_SECTION_INTERNAL: INTERNAL_AD_GROUP,
  TARGET_CONTACT_EDIT_INTERNAL: [
    AD_GROUP.UPDATE,
    AD_GROUP.APPROVE,
    AD_GROUP.SECURITY,
    AD_GROUP.SYSADMIN,
  ],
  EDIT_BUTTON: [
    AD_GROUP.SYSADMIN,
    AD_GROUP.UPDATE,
    AD_GROUP.APPROVE,
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.PARTNERUPDATE,
  ],
  SUPPLIER_TYPE_VIEW_CHANGES_INTERNAL: INTERNAL_AD_GROUP,
  ADD_SUPPLIER_TYPES: [
    AD_GROUP.UPDATE,
    AD_GROUP.APPROVE,
    AD_GROUP.SECURITY,
    AD_GROUP.SYSADMIN,
  ],
}

export const CONTACT_PAGE = {
  DISABLED_MSG_TOOLTIP: INTERNAL_AD_GROUP,
  URL_GENERATOR_AND_ENABLE_BUTTON: [
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.SYSADMIN,
    AD_GROUP.UPDATE,
    AD_GROUP.SECURITY,
    AD_GROUP.PAYMENT,
  ],
  ELLIPSIS_MENU: [AD_GROUP.SYSADMIN, AD_GROUP.SECURITY, AD_GROUP.PARTNERADMIN],
  EDIT_BUTTON: [AD_GROUP.SYSADMIN, AD_GROUP.UPDATE, AD_GROUP.PARTNERADMIN],
  ADD_ASSIGN_BUTTON: [AD_GROUP.SYSADMIN, AD_GROUP.PARTNERADMIN],
  ASSIGN_ROLE_API: [AD_GROUP.SYSADMIN, AD_GROUP.PARTNERADMIN],
  USER_INFO_VISIBLE: INTERNAL_AD_GROUP,
  CONTACT_VALIDATION: [AD_GROUP.PARTNERADMIN],
}

export const LOCATION_PAGE = {
  ADD_BUTTON: [
    AD_GROUP.SYSADMIN,
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.PARTNERUPDATE,
  ],
  EDIT_BUTTON: [
    AD_GROUP.SYSADMIN,
    AD_GROUP.UPDATE,
    AD_GROUP.APPROVE,
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.PARTNERUPDATE,
  ],
  ELLIPSIS_MENU: INTERNAL_AD_GROUP,
}
export const EXTERNAL_USER = {
  ADD_BUTTON: [EXTERNAL_AD_GROUP],
}

export const PROFILE_PAGE = {
  EDIT_BUTTON: [
    AD_GROUP.SYSADMIN,
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.PARTNERUPDATE,
  ],
  SAVE_COMPANY_OFFICER: [
    AD_GROUP.SYSADMIN,
    AD_GROUP.PARTNERADMIN,
    AD_GROUP.PARTNERUPDATE,
  ],
  VIEW_CHANGES_INTERNAL: INTERNAL_AD_GROUP,
}

export const INTERNAL_REVIEW_PAGES = {
  REVIEW_BUTTON: [AD_GROUP.SYSADMIN, AD_GROUP.APPROVE],
}

export const SUPPLY_CHAIN_CERT = {
  INTERNAL_CERT_SCREEN: INTERNAL_AD_GROUP,
  ENABLE_EDIT: [AD_GROUP.APPROVE, AD_GROUP.SYSADMIN, AD_GROUP.PARTNERADMIN],
  AUDIT: INTERNAL_AD_GROUP,
}

export const POL_AGREEMENT = {
  INTERNAL_POL_SCREEN: INTERNAL_AD_GROUP,
  ENABLE_EDIT: [AD_GROUP.APPROVE, AD_GROUP.SYSADMIN, AD_GROUP.PARTNERADMIN],
  AUDIT: INTERNAL_AD_GROUP,
}

export const CERT_OF_INSURANCE = {
  ENABLE_EDIT: [AD_GROUP.APPROVE, AD_GROUP.SYSADMIN, AD_GROUP.PARTNERADMIN],
  AUDIT: INTERNAL_AD_GROUP,
}

export const RELATIONSHIP_PAGE = {
  RELATIONSHIP_APPROVER: INTERNAL_AD_GROUP,
  RELATIONSHIP_DELETE: EXTERNAL_AD_GROUP,
}

export const SUPPLIER_DIVERISTY = {
  EDIT_BUTTON: INTERNAL_AD_GROUP,
}
