export const MERCH_VENDOR_OB = {
  basic_information: [],
  target_setup: [],
  locations: ['manufacturing', 'shipping', 'business_office'],
  relationships: [],
  contacts: [],
  brands: [],
  documents: ['coi', 'pol', 'ctpat'],
  payment: [],
}

export const MERCH_VENDOR_NB = {
  basic_information: [],
  target_setup: [],
  locations: ['manufacturing', 'shipping', 'business_office'],
  relationships: [],
  contacts: [],
  brands: [],
  documents: ['coi', 'pol', 'ctpat'],
  payment: [],
}

export const SALES_REP = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const INDIRECT = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi'],
}

export const FACILITIES_MANAGEMENT = {
  basic_information: [],
  target_setup: [],
  //relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi'],
}

export const PD_PROVIDER = {
  basic_information: [],
  target_setup: [],
  //relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi'],
}

export const MARKETING = {
  basic_information: [],
  target_setup: [],
  //relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi'],
}

export const AIR_CARRIER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const CUSTOMS_BROKER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const CONSOLIDATOR = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const DECONSOLIDATOR = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const OCEAN_CARRIER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const LAND_CARRIER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
  documents: ['coi', 'pol', 'ctpat'],
}
export const LAW_VENDOR = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
}

export const FABRIC = {
  basic_information: [],
  target_setup: [],
  locations: ['manufacturing', 'business_office'],
  relationships: [],
  contacts: [],
  brands: [],
  documents: ['coi', 'pol', 'ctpat'],
  payment: [],
}
export const FACTOR = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const EDI = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const DATA = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const QA_SERVICE_PROVIDER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  payment: [],
}

export const FRS_SERVICE_PROVIDER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const DESIGN_PARTNER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
}

export const BRAND_OWNER = {
  basic_information: [],
  target_setup: [],
  relationships: [],
  locations: ['business_office'],
  contacts: [],
  brands: [],
  documents: ['pol'],
  payment: [],
}
